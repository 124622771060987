import {
    BackendServiceError,
    fetchDataRest,
    fetchDataWebService,
    getDefaultParams,
    localStorageKey,
    saveFile,
    seriesOfTopicsAccessToken,
    topic
} from "./BackendServicesUtils"
import { defaultLogger as logger } from "../globalStates/AppState"
import { Contact, ShareTargetType, SotUser } from "./Types"
import { getProtocolAndHost } from "../environments"
import branding from "../branding/branding"
import { getMyIdFromLocalStorage } from "../globalStates/LoggedInUser"

/*********************************************************************************************
 * CONTACTS LIST
 **********************************************************************************************/
export interface ContactItem {
    group: number
    sotUser: any
    orgaConnectionRequest?: any
    isBookmarked: boolean
}

export interface ContactListResponse {
    contacts: ContactItem[]
    hasNextPage: boolean
    searchString?: string
    extraItems?: any[]
    unViewedConnections?: number
}

export async function loadContactListData(
    profileId: string,
    params: { searchString: string; itemsPerPage: number; page: number; ddbCounter?: number }
): Promise<ContactListResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/contacts`
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { hasNextPage: data.hasNextPage, contacts: data.items, unViewedConnections: data.totalUnviewedConnections }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * EXPORT CONTACTS AS CSV
 **********************************************************************************************/

export async function exportContactsAsCsv(
    profileId: string,
    params: { forceCollaborationFeatureActive?: boolean }
): Promise<string> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/contacts/csv`
    const customHeaders = {
        Accept: "text/csv; charset=utf-8",
        "Content-Type": "text/csv; charset=utf-8"
    }

    try {
        const result = await fetchDataRest(
            defaultRoute,
            params,
            "GET",
            undefined,
            (resp: Response) => saveFile(resp, "contacts.csv"),
            undefined,
            customHeaders
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "ERROR"
    }
}

/*********************************************************************************************
 * EXPORT CONTACTS AS VCARD
 **********************************************************************************************/

export async function exportContactsAsVCard(
    profileId: string,
    params: { forceCollaborationFeatureActive?: boolean }
): Promise<string> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/contacts/vCard`
    const customHeaders = {
        Accept: "text/x-vcard;charset=utf-8",
        "Content-Type": "text/x-vcard;charset=utf-8"
    }

    try {
        const result = await fetchDataRest(
            defaultRoute,
            params,
            "GET",
            undefined,
            (resp: Response) => saveFile(resp, "contacts.vcf"),
            undefined,
            customHeaders
        )
        return result
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return "ERROR"
    }
}

/*********************************************************************************************
 * RELEVANT PROFILES LIST
 **********************************************************************************************/
export async function loadRelevantProfilesListData(
    profileId: string,
    params: {
        searchString: string
        itemsPerPage: number
        page: number
        positionFilter?: string
        userTypeFilter?: string
        topicFilter?: number
        hideConnections?: boolean
    }
): Promise<ContactListResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/relevant`

    if(profileId) {
        try {
            const data = await fetchDataRest(defaultRoute, params, "GET")
            if ((data as BackendServiceError).httpStatus) {
                return data
            } else {
                return { hasNextPage: data.hasNextPage, contacts: data.items }
            }
        } catch (error: any) {
            logger.error({
                message: "BackendServices fetch failed",
                request: defaultRoute,
                params,
                errorMessage: error.message,
                errorStack: error.stack
            })
            return { httpStatus: 500 } as BackendServiceError
        }
    }else {
        logger.error({
            message: "Missing parameter profileId",
            request: defaultRoute,
            params
        })

        return {} as ContactListResponse
    }
}

/*********************************************************************************************
 * CONTACTS REQUEST LIST
 **********************************************************************************************/
export async function loadContactRequestListData(
    profileId: string,
    params: { searchString: string; itemsPerPage: number; page: number; ddbCounter?: number }
): Promise<ContactListResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/requests`
    try {
        // No logedUser at the moment. NEED user alias to implement in route
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return {
                hasNextPage: data.hasNextPage,
                contacts: data.items,
                extraItems: data.extraItems,
                unViewedConnections: data.totalUnviewedConnections
            }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * ORRGANIZATION CONNECT REQUESTS LIST
 **********************************************************************************************/
export async function getOrganizationReqList(profileId: string, organizationId: string): Promise<Array<any> | null> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/organization/${organizationId}/getorgaincomingreq`
    const data = await fetchDataRest(defaultRoute, {}, "GET")
    if (data.length) {
        return data
    } else {
        return null
    }
}

/*********************************************************************************************
 * CONTACTS BLOCKED LIST
 **********************************************************************************************/
export async function loadContactBlockedtListData(
    profileId: string,
    params: { searchString: string; itemsPerPage: number; page: number }
): Promise<ContactListResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/blocks`
    try {
        // No logedUser at the moment. NEED user alias to implement in route
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { hasNextPage: data.hasNextPage, contacts: data.items, extraItems: data.extraItems }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * CONTACTS CONVERSATIONS LIST
 **********************************************************************************************/
export async function loadContactsConversationsListData(
    profileId: string,
    params: object
): Promise<ContactListResponse | BackendServiceError> {
    let defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/conversations`
    let conversationsData
    try {
        conversationsData = await fetchDataRest(defaultRoute, params, "GET")
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
    defaultRoute = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/profiles/contacts`
    let contactsData
    try {
        contactsData = await fetchDataRest(defaultRoute, params, "GET")
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }

    var data = []

    try {
        for (var i = 0; i < conversationsData.items.length; i++) {
            for (var j = 0; j < contactsData.items.length; j++) {
                if (conversationsData.items[i].sotUser.id === contactsData.items[j].sotUser.id) {
                    data.push(conversationsData.items[i])
                }
            }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices unpack conversationsData failed",
            errorMessage: error.message,
            errorStack: error.stack
        })
    }

    conversationsData.items = data

    if ((conversationsData as BackendServiceError).httpStatus) {
        return conversationsData
    } else {
        return { hasNextPage: conversationsData.hasNextPage, contacts: conversationsData.items }
    }
}

/*********************************************************************************************
 * GET CONTACTS
 **********************************************************************************************/
export interface GetContactsResponse {
    content: ContactItem[]
}

export interface GetContactsParams {
    profileId: string
}

export async function getContacts(params: GetContactsParams): Promise<GetContactsResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/profiles/contacts`
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data.items }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * GET ORGANIZATION CONNECTION DETAILS
 **********************************************************************************************/

export interface SendOrganizationConnectionDetailsResponse {
    sotUser: string
    orga: string
    status: string
}

export interface SendOrganizationConnectionDetailsParams {
    profileId: string
    organizationId: string
    message: string | null
}

export async function sendOrganizationConnectionRequest(
    params: SendOrganizationConnectionDetailsParams
): Promise<SendOrganizationConnectionDetailsResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/organizationconnectionrequest/${params.organizationId}`
    const body = {
        message: params.message,
        status: "OPEN"
    }

    try {
        const data = await fetchDataRest(defaultRoute, null, "PUT", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { sotUser: data.sotUser, orga: data.orga, status: data.status }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * USER DETAILS
 **********************************************************************************************/

export interface UserResponse {
    content: Contact
}

export interface UserDetailsParams {
    targetProfileId: string
    loggedInUserId: string
}
export async function loadUserData(params: UserDetailsParams): Promise<UserResponse | BackendServiceError> {
    const defaultRoute = `/seriesoftopicsuser/topic/${topic}/profile/${params.loggedInUserId}/targetProfile/${params.targetProfileId}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data.profile }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * MAKE CONNECTION
 **********************************************************************************************/

export interface MakeConnectionResponse {
    content: any
}

export interface MakeConnectionParams {
    profileId: string
    targetProfileId: string
    message: string | null
    action: string
}

export async function doConnectAction(params: MakeConnectionParams): Promise<MakeConnectionResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/targetProfile/${params.targetProfileId}/connection`
    const body = {
        action: params.action,
        message: params.message
    }
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * SET CONNECTION isViewed
 **********************************************************************************************/
export interface SetConnectionIsViewedResponse {
    content: any
}

export interface SetConnectionIsViewedParams {
    profileId: string
    viewedProfiles: string[]
}

export async function setConnectIsViewed(
    params: SetConnectionIsViewedParams
): Promise<SetConnectionIsViewedResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/personconnectionsviewed`
    const body = { viewedProfiles: params.viewedProfiles }
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * GET REQUESTS
 **********************************************************************************************/
export interface GetRequestsResponse {
    content: any
}

export interface GetRequestsParams {
    profileId: string
}

export async function getRequests(params: GetRequestsParams): Promise<GetRequestsResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/requests`
    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * GET CONNECTIONS
 **********************************************************************************************/
export interface GetConnectionsResponse {
    content: any
}

export interface GetConnectionsParams {
    profileId: string
    beConnectionToken: string
    depth: number
    firstResult: number
    topicFilter?: number
}

export async function getConnectionsService(params: GetConnectionsParams): Promise<GetConnectionsResponse> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/profiles/networkingChartData`

    try {
        const data = await fetchDataRest(defaultRoute, params, "GET")
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { depth: params.depth },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: { nodes: [] } }
    }
}

/*********************************************************************************************
 * REMOVE FROM RELEVANT LIST
 **********************************************************************************************/

export interface removeFromRelevantListParams {
    requestedUser: string
    userToRemove: string
}

export async function removeFromRelevantList(params: removeFromRelevantListParams): Promise<GetConnectionsResponse> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/removefromrecommendationlist/${params.requestedUser}/${params.userToRemove}`

    try {
        const data = await fetchDataRest(defaultRoute, {}, "DELETE")
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: { nodes: [] } }
    }
}

/*********************************************************************************************
 * PROFILE
 **********************************************************************************************/
export interface ProfileDataResponse {
    content: any
}

export interface ProfileImageChangeParams {
    profileId: string
    data: any
}

export async function changeProfileImage(params: ProfileImageChangeParams) {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}/logo`
    const protocolAndHost = getProtocolAndHost()
    const accessDataString = localStorage.getItem(localStorageKey)
    const token = accessDataString ? JSON.parse(accessDataString).jwtToken : null

    const headers = {
        beConnectionToken: token ? token : seriesOfTopicsAccessToken,
        Accept: "application/json",
        "Accept-Encoding": "multipart/form-data"
    }

    try {
        const req = fetch(protocolAndHost + "/rest" + defaultRoute, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            headers: headers,
            body: params.data
        })
        const resp = await req
        return new Promise<any>((resolve, reject) => {
            if (resp.status >= 200 && resp.status < 300) {
                return resolve(resp.json())
            } else {
                resp.json()
                    .then((res) => {
                        reject({ httpStatus: resp.status.toString(), httpStatusText: resp.statusText, responseJson: res })
                    })
                    .catch((e) => {
                        reject({ httpStatus: resp.status.toString(), httpStatusText: resp.statusText, responseJson: null })
                    })
            }
        })
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * DELETE PROFILE IMAGE
 **********************************************************************************************/

export async function deleteProfileImage(profileId: string) {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/logo`
    try {
        const data = await fetchDataRest(defaultRoute, {}, "DELETE")
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error
    }
}

/*********************************************************************************************
 * INTERESTS
 **********************************************************************************************/

export interface InterestDataResponse {
    content: any
}
interface InterestCache {
    [key: string]: any
}
const interestCache: InterestCache = {}
export async function getInterest(): Promise<InterestDataResponse> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/interests`
    const lang = getDefaultParams().lang
    try {
        if (interestCache[lang]) return { content: interestCache[lang] }
        const data = await fetchDataRest(defaultRoute, null, "GET")
        interestCache[lang] = data
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { content: [] }
    }
}

/*********************************************************************************************
 * UPDATE MY PROFILE
 **********************************************************************************************/

export interface ProfileDataResponse {
    content: any
}

export interface ProfileDataParams {
    profileId: string
    profileData: Object
}

export async function updateMyProfileData(params: ProfileDataParams): Promise<ProfileDataResponse> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${params.profileId}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "PUT", params.profileData)
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error // FIXME will break hard
    }
}

/*********************************************************************************************
 * GET MY PROFILE
 **********************************************************************************************/

export async function getMyProfileData(): Promise<ProfileDataResponse> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${getMyIdFromLocalStorage()}`
    try {
        const data = await fetchDataRest(defaultRoute, null, "GET", undefined, undefined, true)
        return { content: getProfileFromBackendResponse({ profile: data }) }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            undefined,
            errorMessage: error.message,
            errorStack: error.stack
        })
        throw error // FIXME will break hard
    }
}

/*********************************************************************************************
 * GET SHOW ME AS CONTACT
 **********************************************************************************************/
export interface Settings {
    visibleAsContact: boolean
    showroomStandby: boolean
}

export async function getOrgaPersonSettings(profileId: string, organizationId: string): Promise<Settings | BackendServiceError> {
    const defaultRoute = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/organization/${organizationId}/settings`

    try {
        const data = await fetchDataRest(
            defaultRoute,
            { topicName: topic, profileId: profileId, organizationId: organizationId },
            "GET",
            undefined
        )
        return data as Settings
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * SET SHOW ME AS CONTACT
 **********************************************************************************************/
export async function setOrgaPersonSettings(
    profileId: string,
    organizationId: string,
    settingsJson: object
): Promise<Settings | BackendServiceError> {
    const defaultRoute = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/organization/${organizationId}/settings`

    try {
        const data = await fetchDataRest(defaultRoute, {}, "PUT", settingsJson)
        return data as Settings
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * REPORT USER
 **********************************************************************************************/

export interface ReportUserResponse {
    content: any
}

export interface ReportUserParams {
    reporterId: string
    reportProfileId: string
    reportType: string
    message: string
}

export async function reportUser(params: ReportUserParams): Promise<ReportUserResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/reporter/${params.reporterId}/reportProfile/${params.reportProfileId}/sendReportEmail`

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", {
            reportType: params.reportType,
            description: params.message
        })
        return { content: data }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { reportType: params.reportType, description: params.message },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * TOGGLE ORGANIZATION DETAILSECTION FROM ORGANIZATION STAFF
 **********************************************************************************************/
export interface DetailSectionToggleResponse {
    showProducts: boolean
    showTrademarks: boolean
}
export enum DetailSectionEnum {
    PRODUCTS = "PRODUCTS",
    TRADEMARKS = "TRADEMARKS"
}

export async function toggleDetailSection(
    profileId: string,
    key: DetailSectionEnum,
    value: boolean,
    organizationId: string
): Promise<DetailSectionToggleResponse | BackendServiceError> {
    const defaultRoute = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/organization/${organizationId}`
    try {
        const data = await fetchDataRest(defaultRoute, { key: key.toString(), value: value }, "POST", undefined)
        return data as DetailSectionToggleResponse
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * ShareExhibitorMailNotification
 **********************************************************************************************/

export interface ShareExhibitorMailNotificationProps {
    targetId: string
    organizationId: string
    type: ShareTargetType
    notes: string
}

export async function shareTargetMailNotification(
    params: ShareExhibitorMailNotificationProps
): Promise<any | BackendServiceError> {
    const defaultRoute = `/seriesoftopicsuser/topic/${topic}/shareTarget/${params.organizationId}/targetProfile/${params.targetId}/sendShareMail`
    try {
        const data = await fetchDataRest(defaultRoute, { type: params.type }, "POST", { notes: params.notes })
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return data
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            params: { notes: params.notes },
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * CORRECT UNREAD COUNTER CONVERSATIONS
 **********************************************************************************************/

export async function correctUnreadCounterConversations(profileId: string, ddbCounterDifference?: number) {
    // FIXME
    // const params = { profileId: profileId, ddbCounterDifference: ddbCounterDifference }
    // const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/correctUnreadCounterConversations`
    // try {
    //     fetchDataRest(defaultRoute, params, "POST")
    // } catch (error: any) {
    //     logger.error({ message: "BackendServices fetch failed", request: defaultRoute, params, errorMessage: error.message, errorStack: error.stack });
    // }
}

/*********************************************************************************************
 * CONNECT PROFILE
 **********************************************************************************************/

export async function connectProfile(profileId: string, token: string): Promise<ProfileResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/profile/${profileId}/connection`
    const body = {
        userToken: token
    }
    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", body)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return getProfileFromBackendResponse(data)
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            body,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

/*********************************************************************************************
 * SERIES_OF_TOPICS_USER LIST
 **********************************************************************************************/

export interface UsersListResponse {
    /**
     * @title Users list response count
     * @description Count number for users list response
     * @title_de Users list response count
     * @description_de Count number for users list response
     */
    count: number
    /**
     * @title Users list
     * @description Configuration for users list
     * @title_de Users list
     * @description_de Configuration for users list
     */
    users: SotUser[]
}

export async function loadUsersData(params: object, signal?: AbortSignal): Promise<UsersListResponse> {
    try {
        const data = await fetchDataWebService("/search", params, signal)
        return { count: data.count, users: data.entities }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: "/search",
            params,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { count: 0, users: [] }
    }
}

/* #region  TOGGLE USER FROM ORGANIZATION STAFF */
/*********************************************************************************************
 * TOGGLE USER FROM ORGANIZATION STAFF
 **********************************************************************************************/
export interface ToggleStaffResponse {
    content: any
}

export interface ToggleStaffParams {
    organizationId: string
    id: string
}

export async function getUserOrgaInfo(params: ToggleStaffParams): Promise<ToggleStaffResponse | BackendServiceError> {
    const route: string = `/seriesoftopicsuser/topic/${topic}/organization/${params.organizationId}/user/${params.id}`

    const data = await fetchDataRest(route, null, "GET")
    if ((data as BackendServiceError).httpStatus) {
        return data
    } else {
        return { content: data }
    }
}

export async function addUserToOrgaStaff(params: ToggleStaffParams): Promise<ToggleStaffResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/organization/${params.organizationId}/user`

    let userToSend = { userId: params.id, personFunction: branding.configuration.defaultStaffPersonFunction }

    try {
        const data = await fetchDataRest(defaultRoute, null, "POST", userToSend)
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            userToSend,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function removeUserFromOrgaStaff(params: ToggleStaffParams): Promise<ToggleStaffResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/organization/${params.organizationId}/user/${params.id}`

    try {
        const data = await fetchDataRest(defaultRoute, null, "DELETE")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}

export async function removePersonFromOrgaStaff(params: ToggleStaffParams): Promise<ToggleStaffResponse | BackendServiceError> {
    const defaultRoute: string = `/seriesoftopicsuser/topic/${topic}/organization/${params.organizationId}/person/${params.id}`

    try {
        const data = await fetchDataRest(defaultRoute, null, "DELETE")
        if ((data as BackendServiceError).httpStatus) {
            return data
        } else {
            return { content: data }
        }
    } catch (error: any) {
        logger.error({
            message: "BackendServices fetch failed",
            request: defaultRoute,
            errorMessage: error.message,
            errorStack: error.stack
        })
        return { httpStatus: 500 } as BackendServiceError
    }
}
/* #endregion */

/* #region  HELPERS */
export interface ProfileResponse {
    beConnectionToken: string
    profile: {
        profileId: string
        firstName?: string
        lastName?: string
        email: string
        type: string
        visible?: boolean
        showfloorStandby?: boolean
    }
}
export function getProfileFromBackendResponse(data: any): ProfileResponse {
    const profile = {
        profileId: data.profile.id,
        remoteId: data.profile.remoteId,
        firstName: data.profile.firstName,
        lastName: data.profile.lastName,
        email: data.profile.email,
        infotext: data.profile.infotext,
        infotextDe: data.profile.infotextDe,
        interests: data.profile.interests,
        lookingfor: data.profile.lookingfor,
        offering: data.profile.offering,
        logoUrl: data.profile.logoUrl,
        matchActive: data.profile.matchActive,
        middleName: data.profile.middleName,
        industry: data.profile.industry,
        industryDe: data.profile.industryDe,
        countrycode: data.profile.countrycode,
        emailLanguage: data.profile.emailLanguage,
        mobile: data.profile.mobile,
        person: data.profile.person,
        phone: data.profile.phone,
        adress1: data.profile.adress1,
        adress2: data.profile.adress2,
        adress3: data.profile.adress3,
        city: data.profile.city,
        postalCode: data.profile.postalCode,
        position: data.profile.position,
        positionDe: data.profile.positionDe,
        salutation: data.profile.salutation,
        salutationDe: data.profile.salutationDe,
        company: data.profile.company,
        languages: data.profile.languages,
        areaOfResponsibility: data.profile.areaOfResponsibility,
        presence: data.profile.presence,
        facebook: data.profile.facebook,
        linkedIn: data.profile.linkedIn,
        googleplus: data.profile.googleplus,
        youTube: data.profile.youTube,
        twitter: data.profile.twitter,
        xing: data.profile.xing,
        pinterest: data.profile.pinterest,
        instagram: data.profile.instagram,
        type: data.profile.type,
        organizations: data.profile.organizations,
        eventDates: data.profile.eventDates,
        events: data.profile.events,
        invitingOrganization: data.profile.invitingOrganization,
        joboffers: data.profile.joboffers
    }

    return {
        beConnectionToken: data.beConnectionToken,
        profile: profile
    }
}
/* #endregion */
